<template>
	<div 
		ref="component"
		:id="id + 'Component'" 
		class="hidden"
	>
		<div>isAuth : {{ isAuth }}</div>
	</div>
</template>

<script>
import store from '@/store'

export default {
	name: 'SuiteWebConfig',
	props: {
		id: {
			type: String,
			default: 'suitewebConfig'
		},
		pageMode: {
			type: String,
			default: 'web'
		},
		userToken: {
			type: String,
			default: null
		},
		isAuth: {
			type: String,
			default: 'false'
		},
		claimCreditUrl: {
			type: String,
			default: '#'
		},
		baseUrl: {
			type: String,
			default: ''
		},
		pagePrefix: {
			type: String,
			default: '/'
		},
		updates: {
			type: String,
			default: null
		}
	},
	watch: {
		userToken(to) {
			store.dispatch('setUserToken', to)
		},
		updates(to) {
			store.dispatch('setUpdates', to)
		}
	},
	mounted() {
		store.dispatch('setBaseUrl', this.baseUrl)
		store.dispatch('setPagePrefix', this.pagePrefix)
		store.dispatch('setPageMode', this.pageMode)
		store.dispatch('setUserToken', this.userToken)
		store.dispatch('setClaimCreditUrl', this.claimCreditUrl)
		// if (this.updates) store.dispatch('setUpdates', this.updates)
		// else store.dispatch('getUpdates')
		this.$emit('loaded')
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>