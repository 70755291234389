<template>
	<div 
		class="rounded-md flex flex-col gap-3 bg-form-field-bg p-3"
		:class="{
			'hidden': !list || (list && list.length < 1)
		}"
	>
		<template v-if="list && list.length > 0">
			<div
				v-for="(item, item_ndx) in list"
				:key="item_ndx"
			>
				<UiLink
					class="cognitive-link"
					:data="item"
				/>
			</div>
			<div class="text-gray-005">
				<UiLink
					class="coginitive-link"
					:data="{ 
						'label': 'View All History',
						'href': 'https://suiteweb.atpointofcare.com/#watson/history',
						'type': 'external'
					}"
				/>
			</div>
		</template>
		<template v-else>
			<div>
				<p>No Previously Asked Questions</p>
			</div>
		</template>
	</div>
</template>

<script>
import store from '@/store'

import UiLink from '@/components/ui/Link'

export default {
	name: 'CognitiveHistoryDropdown',
	components: {
		UiLink
	},
	data() {
		return {
			max: 3
		}
	},
	computed: {
		list() {
			const data = store.getters?.cognitiveHistory
				? this.max
					? store.getters.cognitiveHistory.slice(0, this.max)
					: store.getters.cognitiveHistory
				: []

			return data.map((item) => {
				return { 
					'label': item,
					'href': 'https://suiteweb.atpointofcare.com/#watson',
					'type': 'external',
					'params': [
						{
							'key': 'q',
							'value': item
						}
					]
				}
			})
		}
	},
	methods: {
		async getData() {
			await store.dispatch('getCognitiveHistory')
		}
	}
}
</script>