<template>
	<section 
		ref="component" 
		:id="id + 'Component'" 
		class="suiteweb-global-component navbar w-full"
	>
		<div ref="navBarEl" class="container flex flex-row flex-wrap gap-1">
			<div class="navbar-block order-1 tablet:order-1 flex flex-row justify-center items-center">
				<UiLink
					class="navbar-logo m-1"
					:data="logoLink"
				/>
			</div>
			<div class="navbar-block order-4 tablet:order-2 flex-1 flex flex-row justify-center items-center">
				<CognitiveSearchBar 
					ref="searchBarEl"
					@on-focus="checkActives"
					@form-submit="$emit('search-cognitive', $event)"
				/>
			</div>
			<div 
				class="navbar-block order-3 tablet:order-3 flex flex-row justify-center items-center py-0"
				:class="{
					'bg-red': isNavMenuActive,
				}"
			>
				<div class="desktop:hidden flex flex-row items-center">
					<button 
						v-if="isNavMenuActive" 
						class="text-white hover:text-black"
						@click="hideNavMenu"
					>
						<Close />
					</button>
					<button 
						v-else 
						class="hover:text-red"
						@click="showNavMenu"
					>
						<Menu />
					</button>
				</div>
				<div class="navbar-links hidden desktop:flex desktop:flex-nowrap desktop:gap-8 h-full font-medium">
					<UiLink
						v-for="(link, link_ndx) in pageLinks"
						:key="link_ndx"
						class="navbar-link relative h-100 flex flex-row justify-center items-center"
						:data="link"
					/>
				</div>
			</div>
			<div class="navbar-block order-2 tablet:order-4 flex flex-row justify-center items-center">
				<UserMenu 
					ref="userMenuEl"
					@user-login="userLogin" 
					@user-logout="userLogout"
					:usermenu-overwrite-links="usermenuOverwriteLinks"
				/>
			</div>
		</div>
		<div 
			ref="navMenuEl"
			class="navmenu-wrap fixed w-full overflow-hidden"
			:style="{
				height: '0px'
			}"
		>
			<NavMenu 
				id="navMenu" 
				:page-links="pageLinks"
				@on-click="hideNavMenu"
			/>
		</div>
	</section>
</template>

<script>
import store from '@/store'
import gsap from 'gsap'

import Menu from 'vue-material-design-icons/Menu'
import Close from 'vue-material-design-icons/Close'

import UiLink from '@/components/ui/Link'

import CognitiveSearchBar from '@/components/cognitive/SearchBar'
import UserMenu from '@/components/user/Menu'
import NavMenu from '@/components/nav/Menu'

export default {
	name: 'SuiteWebBar',
	components: {
		Menu,
		Close,
		UiLink,
		CognitiveSearchBar,
		UserMenu,
		NavMenu
	},
	props: {
		id: {
			type: String,
			default: 'suitewebNavBar'
		},
		logoOverwriteLink: {
			type: String,
			default: '{}'
		},
		navbarOverwriteLinks: {
			type: String,
			default: null
		},
		usermenuOverwriteLinks: {
			type: String,
			default: null
		},
		activeCriteria: {
			type: String,
			default: 'pathname'
		}
	},
	data() {
		return {
			isNavMenuActive: false,
			defaultLogoLink: { 
				'label': process.env.VUE_APP_LOGO_TITLE,
				'href': '',
				'icon': process.env.VUE_APP_LOGO_URL,
				'maxWidth': '100%'
			},
			defaultLinks: [
				{ 
					'label': 'Home',
					'href': ''
				},
				{ 
					'label': 'Library',
					'href': 'library' 
				},
				{ 
					'label': 'Updates',
					'href': 'clinician/updates',
					'notification': 'updates'
				},
				{ 
					'label': 'BreakingMed',
					'href': 'https://breakingmed.atpointofcare.com/',
					'target': '_blank',
					'type': 'external',
					'icon': 'https://cdn.atpoc.com/cdn/assets/production/breakingmed/logos/bk-image.png',
					'maxWidth': '95px'
				}
			]
		}
	},
	computed: {
		baseUrl() {
			return store.getters.baseUrl
		},
		pagePrefix() {
			return store.getters.pagePrefix
		},
		logoLink() {
			return Object.assign(this.defaultLogoLink, JSON.parse(this.logoOverwriteLink))
		},
		pageLinks() {
			return this.navbarOverwriteLinks && JSON.parse(this.navbarOverwriteLinks).length
				? JSON.parse(this.navbarOverwriteLinks)
				: this.defaultLinks
		}
	},
	mounted() {
		this.setActiveLink()
		this.setNavMenuPosition()
		window.addEventListener('resize', this.hideNavMenu)
		window.addEventListener('hashchange', this.setActiveLink)
		this.$emit('loaded')
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.hideNavMenu)
		window.removeEventListener('hashchange', this.setActiveLink)
	},
	methods: {
		checkActives() {
			if (this.$refs.userMenuEl.isDropdownActive) this.$refs.userMenuEl.hideDropdown()
			if (this.isNavMenuActive) this.hideNavMenu()
		},
		setActiveLink() {
			Object.keys(this.$refs)
				.forEach((group) => {
					if (group.includes('nav')) {
						this.$refs[group].querySelectorAll('.page-link')
							.forEach((item) => {
								let compare
								const link = item.querySelector('a')
								if (link) {
									let href = link.getAttribute('href')
										
									if (href.charAt(0) === '/' || href.charAt(0) === '#') href = href.substr(1)
									if (href.charAt(href.length - 1) === '/') href = href.slice(0, -1)

									if (this.activeCriteria === 'pathname') compare = window.location.pathname.substr(1)
									else if (this.activeCriteria === 'hash') compare = window.location.hash.substr(1)

									if (compare.charAt(compare.length - 1) === '/') compare = compare.slice(0, -1)

									if (href === compare) item.classList.add('text-red','active')
									else item.classList.remove('text-red','active')
								}
							})
					}
				})
		},
		setNavMenuPosition() {
			const navbar = this.$refs.component
			const navmenu = this.$refs.navMenuEl
			
			gsap.set(navmenu, {
				top: navbar.offsetHeight
			})
		},
		showNavMenu() {
			if (this.$refs.userMenuEl.isDropdownActive) this.$refs.userMenuEl.hideDropdown()
			if (!this.isNavMenuActive) {
				document.body.style.overflowY = 'hidden'
				this.setNavMenuPosition()

				const navbar = this.$refs.component
				const navmenu = this.$refs.navMenuEl

				const tl = gsap.timeline({
					duration: 0.1,
					ease: 'none'
				})

				tl.to(navmenu, {
					height: window.innerHeight - navbar.offsetHeight
				})

				this.isNavMenuActive = true
			}
		},
		hideNavMenu() {
			if (this.isNavMenuActive) {
				document.body.style.overflowY = 'scroll'
				this.setNavMenuPosition()

				const navmenu = this.$refs.navMenuEl

				const tl = gsap.timeline({
					duration: 0.1,
					ease: 'none'
				})

				tl.to(navmenu, {
					height: 0
				})

				this.isNavMenuActive = false
			}
		},
		userLogin() {
			this.$emit('user-login')
		},
		userLogout() {
			this.$emit('user-logout')
		}
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.container { padding: 0 !important; }

.navbar-block {
	padding: 0.75rem;
	background-color: var(--color-white);
}

.navbar-block:nth-child(1) { width: calc((100% - (135px + 48px)) - ((0.25rem * 4) / 2)); }
.navbar-block:nth-child(4) { width: 135px; }

.navbar-logo { max-width: 200px; }

@media (min-width: 768px) {
	.navbar-block:nth-child(1) { width: auto; }
	.navbar-logo { max-width: 200px; }
}
.navbar { z-index: 10; position: relative; }
.navmenu-wrap { z-index: 11; }
</style>

<style scoped>
.user-dropdown { z-index: 11; }
</style>