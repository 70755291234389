<template>
	<button
		class="rounded-md"
		:title="title"
		:class="classList"
		@click="$emit('on-click')"
	><slot></slot></button>
</template>

<script>
export default {
	name: 'UiButton',
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		title: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: 'md'
		},
	},
	computed: {
		classList() {
			const padding = {
				'sm': 'px-2 py-3',
				'md': 'px-6 py-4',
				'lg': 'px-8 py-6',
				'sm-full': 'px-2 py-3 w-full',
				'md-full': 'px-6 py-4 w-full',
				'lg-full': 'px-8 py-6 w-full'
			}

			return [
				padding[this.size],
				`bg-btn-${ this.type }-bg`, // default bg color
				`text-btn-${ this.type }-text`,  // default text color,
				`hover:bg-btn-${ this.type }-hover-bg`, // hover bg color
				`hover:text-btn-${ this.type }-hover-text`, // hover text color
			]
		}
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>