<template>
	<div class="user-menu w-full max-w-full text-12px">
		<div 
			v-if="isAuth"
		>
			<UiButton
				class="dropdown-btn"
				@click="toggleDropdown"
				:type="dropdownButtonType"
				size="sm"
			>
				<span class="flex flex-row flex-nowrap items-center" @click.prevent>
					Welcome 
					<MenuUp v-if="isDropdownActive"/>
					<MenuDown v-else/>
				</span>
			</UiButton>
			<div 
				class="dropdown-wrap fixed z-50 overflow-hidden mt-1"
				:style="{
					width: '0px',
					height: '0px'
				}"
			>
				<UserDropdown 
					id="userDropdown" 
					@user-logout="userLogout" 
					@on-click="hideDropdown"
					:usermenu-overwrite-links="usermenuOverwriteLinks"
				/>
			</div>
		</div>
		<div 
			v-else
			class="w-full max-w-full"
		>
			<UiButton 
				class="w-full max-w-full" 
				size="sm"
				@click="userLogin"
			>Login</UiButton>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import store from '@/store'
import gsap from 'gsap'

import MenuUp from 'vue-material-design-icons/MenuUp'
import MenuDown from 'vue-material-design-icons/MenuDown'

import UiButton from '@/components/ui/Button'
import UserDropdown from '@/components/user/Dropdown'

export default {
	name: 'UserMenu',
	components: {
		MenuUp,
		MenuDown,
		UiButton,
		UserDropdown,
	},
	props: {
		usermenuOverwriteLinks: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isDropdownActive: false
		}
	},
	computed: {
		isAuth() {
			return !!store.getters.isAuth
		},
		dropdownButtonType() {
			return this.isDropdownActive ? 'primary-active' : 'primary'
		}
	},
	mounted() {
		window.addEventListener('click', (evt) => {
			if (evt.target.tagName.toLowerCase() !== 'suiteweb-navbar' && this.isDropdownActive) this.hideDropdown()
		})
		window.addEventListener('scroll', () => {
			if (this.isDropdownActive) this.hideDropdown()
		})
	},
	unmounted() {
		window.removeEventListener('click', () => {})
		window.removeEventListener('scroll', () => {})
	},
	methods: {
		toggleDropdown() {
			if (this.isDropdownActive) this.hideDropdown()
			else this.showDropdown()
		},
		showDropdown() {
			if (this.$parent.isNavMenuActive) this.$parent.hideNavMenu()
			this.setDropdownPosition()
			if (!this.isDropdownActive) {
				const dropdown = this.$el.querySelector('.dropdown-wrap')
				const menu = dropdown.querySelector('#userDropdown')

				const tl = gsap.timeline({
					duration: 0.1,
					ease: 'none'
				})

				tl.to(dropdown, {
					width: menu.offsetWidth,
					height: menu.offsetHeight
				})

				this.isDropdownActive = true
			}
		},
		hideDropdown() {
			this.setDropdownPosition()
			if (this.isDropdownActive) {
				const dropdown = this.$el.querySelector('.dropdown-wrap')

				const tl = gsap.timeline({
					duration: 0.1,
					ease: 'none'
				})

				tl.to(dropdown, {
					width: 0,
					height: 0
				})

				this.isDropdownActive = false
			}
		},
		setDropdownPosition() {
			const btn = this.$el.querySelector('.dropdown-btn')
			const dropdown = this.$el.querySelector('.dropdown-wrap')

			// get coordinates
			const btnRect = btn.getBoundingClientRect()
			const btnCoords = { 
				bottom: btnRect.bottom + window.pageYOffset, 
				right: window.innerWidth - btnRect.right
			}

			// set position
			gsap
				.set(dropdown, {
					top: btnCoords.bottom,
					right: btnCoords.right
				})
		},
		userLogin() {
			this.$emit('user-login')
		},
		userLogout() {
			this.$emit('user-logout')
			this.hideDropdown()
		}
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
.dropdown-wrap {
	z-index: 11;
}
</style>