<template>
	<div class="page-link">
		<template v-if="type === 'button'">
			<button
				class="relative hover:text-red"
				@click="onClick"
				:title="label"
			>
				<template v-if="icon">
					<UiLogo
						:src="icon"
						:max-width="maxWidth"
					/>
				</template>
				<template v-else>
					<span>{{ label }}</span>
					<sup 
						v-if="notification"
						class="text-red text-10px absolute top-0 right-0 transform translate-x-full -translate-y-full"
					>{{ notification }}</sup>
				</template>
			</button>
		</template>
		<template v-else>
			<a 
				class="relative hover:text-red"
				:href="href"
				:target="target"
				:title="label"
			>
				<template v-if="icon">
					<UiLogo
						:src="icon"
						:max-width="maxWidth"
					/>
				</template>
				<template v-else>
					<span>{{ label }}</span>
					<sup 
						v-if="notification"
						class="text-red text-10px absolute top-0 right-0 transform translate-x-full -translate-y-3/4"
					>
						<MaterialIcon
							name="bell"
							color="red"
							size="10"
						/>
					</sup>
				</template>
			</a>
		</template>
	</div>
</template>

<script>
import store from '@/store'

import UiLogo from '@/components/ui/Logo'
import MaterialIcon from '@/components/MaterialIcon'

export default {
	name: 'PageLinks',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	components: {
		UiLogo,
		MaterialIcon
	},
	computed: {
		pagePrefix() {
			return store.getters.pagePrefix
		},
		type() {
			return this.data?.type || 'link'
		},
		onClick() {
			return this.data?.onClick || null
		},
		icon() {
			return this.data?.icon || null
		},
		maxWidth() {
			return this.data?.maxWidth || '100%'
		},
		label() {
			return this.data?.label || 'Link'
		},
		target() {
			return this.data?.target || '_self'
		},
		href() {
			const prefix = this.type === 'external'
				? ''
				: this.pagePrefix 
			const href = this.data.href || this.data.href === ''
					? this.data.href
					: '#'
			const params = this.data?.params
				? '?' + this.data.params
					.map((param) => {
						const { key, value, type } = param
						const val = type === 'store'
							? store.getters[value]
							: value
						return key + '=' + encodeURIComponent(val)
					})
					.join('&')
				: ''

			return (
					this.data.type === 'external'
					? ''
					: store.getters.baseUrl
				) + (
					prefix === '#'
					? params + prefix + href
					: prefix + href + params
				)
		},
		notification() {
			return store.getters[this.data?.notification] || null
		}
	},
}
</script>