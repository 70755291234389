<template>
	<div class="logo h-auto">
		<img 
			class="w-full" 
			:style="{
				'maxWidth': maxWidth
			}"
			:src="src" 
		/>
	</div>
</template>

<script>
export default {
	name: 'UiLogo',
	props: {
		src: {
			type: String,
			required: true
		},
		maxWidth: {
			type: String,
			default: 'auto'
		}
	}
}
</script>