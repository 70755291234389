<template>
	<div class="user-dropdown bg-white text-12px w-usermenu min-w-usermenu max-w-usermenu">
		<div class="w-full grid grid-cols-1 gap-2px text-center">
			<UiLink
				v-for="(link, link_ndx) in pageLinks"
				:key="link_ndx"
				class="usermenu-link p-3 bg-bg-001"
				:data="link"
			/>
			<UiLink
				class="usermenu-link p-3 bg-bg-001"
				:data="{ 
					'label': 'Log Out',
					'type': 'button',
					'onClick': userLogout
				}"
			/>
		</div>
	</div>
</template>

<script>
import UiLink from '@/components/ui/Link'

export default {
	name: 'UserDropdown',
	components: {
		UiLink,
	},
	props: {
		usermenuOverwriteLinks: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			defaultLinks: [
				{ 
					'label': 'CME Tracker',
					'href': 'https://secureapi.atpoc.com/beta/poc-earn-cme/tracker.html',
					'target': '_blank',
					'type': 'external',
					'params': [
						{
							'key': 'token',
							'value': 'userToken',
							'type': 'store'
						}
					]
				},
				{ 
					'label': 'Profile Settings',
					'href': 'user/profile',
				},
				{ 
					'label': 'Specialty Settings',
					'href': 'user/therapeutic-areas',
				},
			]
		}
	},
	computed: {
		pageLinks() {
			return this.usermenuOverwriteLinks && JSON.parse(this.usermenuOverwriteLinks).length
				? JSON.parse(this.usermenuOverwriteLinks)
				: this.defaultLinks
		}
	},
	methods: {
		userLogout() {
			this.$emit('user-logout')
		}
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>