import { defineCustomElement } from 'vue'

import config from './views/Config.ce.vue'
// import header from './views/PageHeader.ce.vue'
import navbar from './views/NavBar.ce.vue'
// import navmenu from './views/NavMenu.ce.vue'
import footer from './views/Footer.ce.vue'
import button from './views/Button.ce.vue'
// import cmeInfoBar from './views/CmeInfoBar.ce.vue'
// import cmeInfoModal from './views/CmeInfoModal.ce.vue'
// import cmeRegModal from './views/CmeRegModal.ce.vue'
// import breakingNews from './views/BreakingNews.ce.vue'

const configElement = defineCustomElement(config)
// const headerElement = defineCustomElement(header)
const navbarElement = defineCustomElement(navbar)
// const navmenuElement = defineCustomElement(navmenu)
const footerElement = defineCustomElement(footer)
const buttonElement = defineCustomElement(button)
// const cmeInfoBarElement = defineCustomElement(cmeInfoBar)
// const cmeInfoModalElement = defineCustomElement(cmeInfoModal)
// const cmeRegModalElement = defineCustomElement(cmeRegModal)
// const breakingNewsElement = defineCustomElement(breakingNews)

customElements.define('suiteweb-config', configElement)
// customElements.define('suiteweb-header', headerElement)
customElements.define('suiteweb-navbar', navbarElement)
// customElements.define('suiteweb-navmenu', navmenuElement)
customElements.define('suiteweb-button', buttonElement)
customElements.define('suiteweb-footer', footerElement)
// customElements.define('suiteweb-cme-info-bar', cmeInfoBarElement)
// customElements.define('suiteweb-cme-info-modal', cmeInfoModalElement)
// customElements.define('suiteweb-cme-reg-modal', cmeRegModalElement)
// customElements.define('suiteweb-breaking-news', breakingNewsElement)