<template>
	<div class="navmenu relative desktop:none w-full h-full bg-bg-001 p-20px">
		<div class="bg-white w-full h-full">
			<div class="w-full h-full flex flex-col justify-start items-center">
				<UiLink
					v-for="(link, link_ndx) in pageLinks"
					:key="link_ndx"
					class="navmenu-link relative w-full flex flex-row justify-center items-center py-8 px-4 border-b-1"
					:data="link"
					@click="$emit('on-click')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import UiLink from '@/components/ui/Link'

export default {
	name: 'NavMenu',
	components: {
		UiLink,
	},
	props: {
		pageLinks: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		onClick() {
			this.$emit('on-click')
		}
	}
}
</script>