<template>
	<div 
		class="spacer"
		:class="classList"
		:style="{
			'width': width,
			'minWidth': width,
			'maxWidth': width,
			'height': height,
			'minHeight': height,
			'maxHeight': height,
		}"
	></div>
</template>

<script>
export default {
	name: 'UiSpacer',
	props: {
		width: {
			type: String,
			default: '1px'
		},
		height: {
			type: String,
			default: '1px'
		},
		color: {
			type: String,
			default: 'gray-004'
		}
	},
	computed: {
		classList() {
			return [
				`bg-` + this.color
			]
		}
	}
}
</script>