import { createStore } from 'vuex' 
import axios from 'axios'

const state = () => {
	return {
		baseUrl: null,
		pagePrefix: null,
		pageMode: null,
		userToken: null,
		isAuth: false,
		claimCreditUrl: null,
		cognitiveHistory: null,
		updates: 1
	}
}

const mutations = {
	setBaseUrl(state, value) {
		state.baseUrl = value
	},
	setPagePrefix(state, value) {
		state.pagePrefix = value
	},
	setPageMode(state, value) {
		state.pageMode = value
	},
	setIsAuth(state, value) {
		state.isAuth = value
	},
	setUserToken(state, value) {
		state.userToken = value
	},
	setClaimCreditUrl(state, value) {
		state.claimCreditUrl = value
	},
	setUpdates(state, value) {
		state.updates = value
	},
	setCognitiveHistory(state, value) {
		state.cognitiveHistory = value
	}
}

const actions = {
	async submitAnalytics({ state }, payload) {
		const defaultData = {
			app_id: '20000',
			appname: location.href,
			apptype: 'breakingmed',
			category: 'poc',
			metavalue: `${ location.href } | BreakingMED`,
			event: null,
			label: null,
			platform: 'web',
			session: null,
			token: state.userToken,
			userAgent: navigator.userAgent,
			value: 1,
		}

		const params = {}
	
		const body = Object.assign(defaultData, payload || {})

		if (body?.token !== '') params.token = body.token
	
		const urlParams = Object
			.keys(params)
			.map((key) => { 
				return key + '=' + params[key]
			})
			.join('&')
			|| null
	
		const url = process.env.VUE_APP_ANALYTICS_URL + (urlParams ? '?' + urlParams : '')

		await axios.post(url, body, {
			headers: {
				'content-type': 'text/json'
			}
		})
			.then(() => { console.log(body) })
			.catch((e) => console.log(e))
	},
	setBaseUrl({ commit }, value) {
		commit('setBaseUrl', value)
	},
	setPagePrefix({ commit }, value) {
		commit('setPagePrefix', value)
	},
	setPageMode({ commit }, value) {
		commit('setPageMode', value)
	},
	setUserToken({ commit }, value) {
		if (value) {
			commit('setUserToken', value)
			commit('setIsAuth', true)
		}
		else {
			commit('setUserToken', null)
			commit('setIsAuth', false)
		}
	},
	setClaimCreditUrl({ commit }, value) {
		commit('setClaimCreditUrl', value)
	},
	setUpdates({ commit }, value) {
		commit('setUpdates', value)
	},
	async getUpdates({ state, commit }) {
		const { data } = await axios.get(process.env.VUE_APP_UPDATES_API_URL + (state.userToken ? '?token=' + state.userToken : ''))
		commit('setUpdates', data.n_records)
	},
	async getCognitiveHistory({ state, commit }) {
		if (state.userToken) {
			const { data } = await axios.get(process.env.VUE_APP_COGNITIVE_HISTORY_API_URL + '?token=' + state.userToken)
			commit('setCognitiveHistory', data.log_history)
		}
		else {
			commit('setCognitiveHistory', null)
		}
	}
}

const getters = {
	baseUrl(state) {
		return state.baseUrl
	},
	pagePrefix(state) {
		return state.pagePrefix
	},
	isAuth(state) {
		return state.isAuth
	},
	userToken(state) {
		return state.userToken
	},
	claimCreditUrl(state) {
		return state.claimCreditUrl
	},
	updates(state) {
		return state.updates
	},
	cognitiveHistory(state) {
		return state.cognitiveHistory
	}
}

export default createStore({
	state,
	mutations,
	actions,
	getters
})