<template>
	<UiButton
		ref="component"
		:title="title"
		:type="type"
		:size="size"
		@on-click="$emit('on-click')"
	>{{ label }}</UiButton>
</template>

<script>
import UiButton from '@/components/ui/Button'

export default {
	name: 'SuiteWebButton',
	components: {
		UiButton,
	},
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		label: {
			type: String,
			default: 'Button'
		},
		title: {
			type: String,
			default: ''
		},
		size: {
			type: String,
			default: 'md'
		}
	},
	mounted() {
		this.$emit('loaded')
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>