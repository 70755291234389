<template>
	<footer 
		ref="component" 
		:id="id + 'Component'" 
		class="suiteweb-global-component footer w-full max-w-full py-8 md:py-10 bg-transparent text-tiny"
	>
		<div class="container max-w-full grid grid-cols-12 mobile-sm:gap-x-5 gap-y-10">
			<div class="col-span-12 tablet:col-span-9 laptop:col-span-6">
				<div class="grid grid-cols-1 mobile-sm:grid-cols-3 gap-5">
					<div>
						<nav class="grid grid-cols-1 gap-2">
							<UiLink
								v-for="(link, link_ndx) in pageLinks.col1"
								:key="link_ndx"
								class="footer-page-link"
								:data="link"
							/>
						</nav>
					</div>
					<div>
						<nav class="grid grid-cols-1 gap-2">
							<UiLink
								v-for="(link, link_ndx) in pageLinks.col2"
								:key="link_ndx"
								class="footer-page-link"
								:data="link"
							/>
						</nav>
					</div>
					<div>
						<nav class="grid grid-cols-1 gap-2">
							<UiLink
								v-for="(link, link_ndx) in pageLinks.col3"
								:key="link_ndx"
								class="footer-page-link"
								:data="link"
							/>
						</nav>
					</div>
				</div>
			</div>
			<div class="col-span-12 tablet:col-span-3 laptop:col-span-2">
				<div class="grid grid-cols-1 mobile-sm:grid-cols-2 tablet:grid-cols-1 gap-2">
					<div>
						<p>@Point of Care™</p>
						<p>1515 W. Cypress Creek Road</p>
						<p>Fort Lauderdale, FL 33309</p>
						<p><a href="tel:+19738908988">973.890.8988</a></p>
					</div>
					<div class="flex felx-row flex-nowrap gap-3 justify-start mobile-sm:justify-end tablet:justify-start items-center">
						<div>
							<UiLink
								class="footer-page-link footer-logo m-1"
								:data="logoLink"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="col-span-12 laptop:col-span-4 grid grid-cols-1 gap-2 text-8px laptop:text-10px">
				<div class="flex flex-col gap-x0.5 mb-4">
					<p>We appreciate our customers and hold ourselves to the highest privacy standards.</p>
					<a 
						:href="`${ baseUrl }ccpa-request-form`"
					><p>Access My Information or Delete My Information</p></a>
				</div>
				<!-- added currentYear RV -->
				<p>&copy; Copyright {{ currentYear }} Kaplan North America, LLC. All Rights Reserved.</p>
				<p>Peer Reviewed Continuing Medical Education Courses | Free CME/CE Courses & Resources PROJECTS IN KNOWLEDGE, PROJECTS IN KNOWLEDGE AT THE INTERSECTION OF KNOWLEDGE AND OUTCOMES SINCE 1980, TX REPORTER, MEDIMAGE CASES, MEDIMAGE CASES PICTURES AND A THOUSAND WORDS, are registered trademarks of Kaplan North America, LLC and the At Point of Care mark is owned by Kaplan North America, LLC. 1515 West Cypress Creek Road, Ft. Lauderdale, FL 33309.</p>
				<div class="flex flex-col gap-x0.5 mt-4">
					<a href="https://kaplan-tqbdn.formstack.com/forms/privacyrequest"><p>Do Not Sell or Share My Data</p></a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import store from '@/store'

import UiLink from '@/components/ui/Link'

export default {
	name: 'SuiteWebFooter',
	components: {
		UiLink,
	},
	props: {
		id: {
			type: String,
			default: 'suitewebFooter'
		},
		logoOverwriteLink: {
			type: String,
			default: '{}'
		},
		footerOverwriteLinks: {
			type: String,
			default: null
		},
	},
	data() {
		return {
			defaultLogoLink: { 
				'label': process.env.VUE_APP_LOGO_TITLE,
				'href': 'library',
				'icon': process.env.VUE_APP_LOGO_URL,
				'maxWidth': '100%'
			},
			defaultLinks: {
				col1: [
					{
						'label': 'Home',
						'href': '',
					},
					{
						'label': 'How It Works',
						'href': 'http://atpointofcare.com/platform/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Contact Us',
						'href': 'http://atpointofcare.com/contact-us/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'About Us',
						'href': 'http://atpointofcare.com/about-us/',
						'target': '_blank',
						'type': 'external',
					},
				],
				col2: [
					{
						'label': 'For HCPs',
						'href': 'http://atpointofcare.com/our-apps/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'For Patients',
						'href': 'https://atpointofcare.com/patient-companion-apps/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Our Team',
						'href': 'http://atpointofcare.com/our-team/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Press Releases',
						'href': 'http://atpointofcare.com/press-releases/',
						'target': '_blank',
						'type': 'external',
					},
				],
				col3: [
					{
						'label': 'Media Contact',
						'href': 'http://atpointofcare.com/media-contact/',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Terms of Service',
						'href': 'https://atpointofcare.com/policies.html#tos',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Privacy Policy',
						'href': 'https://atpointofcare.com/policies.html#privacy-policy',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'CCPA',
						'href': 'https://atpointofcare.com/policies.html#ccpa',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Terms of Use',
						'href': 'https://atpointofcare.com/policies.html#tou',
						'target': '_blank',
						'type': 'external',
					},
					{
						'label': 'Do Not Sell or Share My Data',
						'href': 'https://kaplan-tqbdn.formstack.com/forms/privacyrequest',
						'target': '_blank',
						'type': 'external',
					},
				]
			},
			currentYear: null // Add a new date
		}
	},
	computed: {
		baseUrl() {
			return store.getters.baseUrl
		},
		pagePrefix() {
			return store.getters.pagePrefix
		},
		logoLink() {
			return Object.assign(this.defaultLogoLink, JSON.parse(this.logoOverwriteLink))
		},
		pageLinks() {
			return this.footerOverwriteLinks && Object.keys(JSON.parse(this.footerOverwriteLinks)).length
				? JSON.parse(this.footerOverwriteLinks)
				: this.defaultLinks
		}
	},
	created() {
		// Set the current Year
		this.currentYear = new Date().getFullYear();
	},
	mounted() {
		this.$emit('loaded')
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style>
.footer-logo { max-width: 160px; }
</style>