<template>
	<div class="cognitive-searchbar relative w-full flex flex-row flex-nowrap gap-4 items-center text-12px">
		<div 
			class="flex-1 flex flex-row flex-nowrap items-center border-1 rounded-md overflow-hidden bg-white"
			:class="{
				'border-red': this.isActive,
				'border-form-field-border': !this.isActive
			}"
		>
			<form 
				class="flex-1 p-3 bg-white"
				@submit.prevent="submitSearch"
			>
				<input 
					type="text" 
					ref="searchBar"
					v-model="query"
					@focus="activate"
					@blur="inactive"
					placeholder="ask your clinical question here!"
				/>
			</form>
			<UiSpacer 
				width="1px" 
				height="28px" 
				color="form-field-border" 
			/>
			<div class="p-3 bg-white">
				<UiLogo 
					src="https://cdn.atpoc.com/assets/production/cognitive/ask-cognitive.png"
					max-width="95px"
					class="cursor-pointer"
					@click="focusSearchBar"
				/>
			</div>
		</div>
		<div 
			ref="dropdownWrap" 
			class="absolute bottom-0 w-full transform translate-y-full overflow-hidden"
			:style="{
				'height': 0
			}"
		>
			<CognitiveHistoryDropdown ref="dropdown" />
		</div>
	</div>
</template>

<script>
import gsap from 'gsap'

import UiLogo from '@/components/ui/Logo'
import UiSpacer from '@/components/ui/Spacer'

import CognitiveHistoryDropdown from '@/components/cognitive/HistoryDropdown'

export default {
	name: 'CognitiveSearchBar',
	components: {
		UiLogo,
		UiSpacer,
		CognitiveHistoryDropdown,
	},
	data() {
		return {
			isActive: false,
			query: ''
		}
	},
	methods: {
		focusSearchBar() {
			this.$refs.searchBar.focus()
		},
		activate() {
			this.isActive = true
			this.showDropdown()

			this.$emit('on-focus')
		},
		inactive() {
			this.isActive = false
			this.hideDropdown()
		},
		async showDropdown() {
			const tl = gsap.timeline({
				duration: 0.05,
				ease: 'none'
			})

			await this.$refs.dropdown.getData()

			tl.to(this.$refs.dropdownWrap, {
				height: this.$refs.dropdown.$el.offsetHeight
			})
		},
		hideDropdown() {
			const tl = gsap.timeline({
				duration: 0.1,
				ease: 'none'
			})

			tl.to(this.$refs.dropdownWrap, {
				height: 0
			})
		},
		submitSearch() {
			this.$emit('form-submit', { query: this.query })
			this.query = ''
		},
	}
}
</script>

<style src="@/assets/css/tailwind.css"></style>
<style src="@/assets/css/_fontface.css"></style>
<style src="@/assets/css/_variables.css"></style>
<style src="@/assets/css/_global.css"></style>
<style src="@/assets/css/_animations.css"></style>
<style src="@/assets/css/main.css"></style>

<style scoped>
input { padding: 0.5rem; }
</style>